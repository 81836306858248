<template>
  <v-tabs v-model="tabsNavigation" show-arrows>
    <v-tab>Exercise</v-tab>
    <v-tab>Videos</v-tab>
    <v-tab>Instruction</v-tab>
    <v-tab>Tips</v-tab>
    <v-tab>Detailed instructions</v-tab>

    <v-tabs-items v-model="tabsNavigation" class="pa-5">
      <v-tab-item>
        <exercise-details-tab-content :exercise="item" />
      </v-tab-item>

      <v-tab-item>
        <dl>
          <dt>Video url (male)</dt>
          <dd>{{ item.video.url }}</dd>

          <div v-if="item.video.url.length">
            <dt>Preview</dt>
            <dd>
              <video :src="item.video.url" height="240px" preload="metadata" controls />
            </dd>
          </div>

          <dt>Video url (female)</dt>
          <dd>{{ item.videoFemale.url }}</dd>

          <div v-if="item.videoFemale.url.length">
            <dt>Preview</dt>
            <dd>
              <video :src="item.videoFemale.url" height="240px" preload="metadata" controls />
            </dd>
          </div>
        </dl>
      </v-tab-item>

      <v-tab-item>
        <dl>
          <dt>Image url (male)</dt>
          <dd>{{ item.instruction.imageUrl }}</dd>

          <div v-if="item.instruction.imageUrl.length">
            <dt>Preview</dt>
            <dd>
              <v-img-ex
                :src="item.instruction.imageUrl"
                max-width="400"
                max-height="400"
                v-on="$listeners"
              />
            </dd>
          </div>

          <dt>Image url (female)</dt>
          <dd>{{ item.instruction.imageUrlFemale }}</dd>

          <div v-if="item.instruction.imageUrlFemale.length">
            <dt>Preview</dt>
            <dd>
              <v-img-ex
                :src="item.instruction.imageUrlFemale"
                max-width="400"
                max-height="400"
                v-on="$listeners"
              />
            </dd>
          </div>

          <dt>Description</dt>
          <dd class="text-pre-line">
            {{ item.instruction.description }}
          </dd>
        </dl>
      </v-tab-item>

      <v-tab-item>
        <div
          v-for="(tip, tipIndex) in item.tips"
          :key="`tip-${tipIndex}`"
          class="mb-10"
        >
          <h3>
            Tip {{ tipIndex + 1 }}/{{ item.tips.length }}
          </h3>
          <v-divider class="mb-5"></v-divider>

          <tip-details-tab-content :tip="tip" />
        </div>
      </v-tab-item>

      <v-tab-item>
        <dl class="mb-5">
          <dt>Title</dt>
          <dd>{{ item.details.instructions.title }}</dd>
        </dl>

        <div
          v-for="(image, imageIndex) in item.details.instructions.images"
          :key="`image-${imageIndex}`"
          class="mb-5"
        >
          <h3>
            Image {{ imageIndex + 1 }}/{{ item.details.instructions.images.length }}
          </h3>
          <v-divider class="mb-5"></v-divider>

          <dl>
            <dt>Title</dt>
            <dd>{{ image.title }}</dd>

            <dt>Image url</dt>
            <dd>{{ image.imageUrl }}</dd>

            <div v-if="image.imageUrl.length">
              <dt>Preview</dt>
              <dd>
                <v-img-ex
                  :src="image.imageUrl"
                  max-width="400"
                  max-height="400"
                  v-on="$listeners"
                />
              </dd>
            </div>
          </dl>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { ref } from 'vue'
import ExerciseDetailsTabContent from '@/components/ExerciseDetailsTabContent.vue'
import TipDetailsTabContent from '@/components/TipDetailsTabContent.vue'
import VImgEx from '@/components/VImgEx.vue'

export default {
  components: {
    ExerciseDetailsTabContent,
    TipDetailsTabContent,
    VImgEx
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
  },

  setup() {
    const tabsNavigation = ref(0)

    return {
      tabsNavigation,
    }
  },
}
</script>
