<template>
  <v-dialog :value="value" max-width="1000" scrollable persistent @input="$emit('input', $event)">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <ValidationObserver ref="formObserver">
          <v-form :disabled="isLoading">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <vee-text-field v-model="item.id" label="Id" disabled />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-text-field
                    v-model="item.uniqueKey"
                    rules="required|max:128"
                    label="Unique key*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-text-field
                    v-model="item.name"
                    rules="required|max:128"
                    label="Name*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.category"
                    :rules="'required|oneOf:' + Object.values(ExerciseCategory).join(',')"
                    :items="[''].concat(Object.values(ExerciseCategory))"
                    label="Category*"
                  />
                </v-col>

                <v-col cols="6" sm="3">
                  <vee-text-field
                    v-model="item.time"
                    rules="required|integer"
                    label="Time (min)*"
                    type="number"
                    min="0"
                  />
                </v-col>

                <v-col cols="6" sm="3">
                  <vee-text-field
                    v-model="item.countdownTime"
                    rules="integer"
                    label="Countdown time (s)"
                    type="number"
                    min="0"
                  />
                </v-col>

                <v-col cols="12">
                  <h3>Tags</h3>
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-combobox
                    v-model="item.tags"
                    label="Tags"
                    multiple
                    small-chips
                    clearable
                    append-icon=""
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-combobox
                    v-model="item.searchTags"
                    label="Search tags"
                    clearable
                    multiple
                    small-chips
                    append-icon=""
                  />
                </v-col>

                <v-col cols="12">
                  <h3>Thumbnails</h3>
                </v-col>

                <v-col cols="12">
                  <upload-file
                    accept="image/*"
                    rules="imageFile"
                    label="Thumbnail file (male)*"
                    @uploadSuccess="item.thumbnail = $event.url"
                  />
                  <vee-text-field
                    :value="item.thumbnail"
                    rules="required|url"
                    clearable
                    dense
                    filled
                    @input="item.thumbnail = $event ? $event : ''"
                  />

                  <upload-file
                    accept="image/*"
                    rules="imageFile"
                    label="Thumbnail file (female)"
                    @uploadSuccess="item.thumbnailFemale = $event.url"
                  />
                  <vee-text-field
                    :value="item.thumbnailFemale"
                    rules="url"
                    clearable
                    dense
                    filled
                    @input="item.thumbnailFemale = $event ? $event : ''"
                  />
                </v-col>

                <v-col cols="12">
                  <h3>Videos</h3>
                </v-col>

                <v-col cols="12">
                  <upload-file
                    accept="video/*"
                    rules="videoFile"
                    label="Video file (male)*"
                    @uploadSuccess="item.video.url = $event.url"
                  />
                  <vee-text-field
                    :value="item.video.url"
                    rules="required|url"
                    clearable
                    dense
                    filled
                    @input="item.video.url = $event ? $event : ''"
                  />

                  <upload-file
                    accept="video/*"
                    rules="videoFile"
                    label="Video file (female)"
                    @uploadSuccess="item.videoFemale.url = $event.url"
                  />
                  <vee-text-field
                    :value="item.videoFemale.url"
                    rules="url"
                    clearable
                    dense
                    filled
                    @input="item.videoFemale.url = $event ? $event : ''"
                  />
                </v-col>

                <v-col cols="12">
                  <h3>Instruction</h3>
                </v-col>

                <v-col cols="12">
                  <upload-file
                    accept="image/*"
                    rules="imageFile"
                    label="Instruction image url (male)*"
                    @uploadSuccess="item.instruction.imageUrl = $event.url"
                  />
                  <vee-text-field
                    :value="item.instruction.imageUrl"
                    rules="required|url"
                    clearable
                    dense
                    filled
                    @input="item.instruction.imageUrl = $event ? $event : ''"
                  />

                  <upload-file
                    accept="image/*"
                    rules="imageFile"
                    label="Instruction image url (female)"
                    @uploadSuccess="item.instruction.imageUrlFemale = $event.url"
                  />
                  <vee-text-field
                    :value="item.instruction.imageUrlFemale"
                    rules="url"
                    clearable
                    dense
                    filled
                    @input="item.instruction.imageUrlFemale = $event ? $event : ''"
                  />

                  <vee-textarea
                    v-model="item.instruction.description"
                    rules="required"
                    label="Description*"
                    rows="1"
                    counter
                    auto-grow
                  />
                </v-col>

                <v-col cols="12">
                  <h3>Tips</h3>
                </v-col>

                <v-col cols="12">
                  <v-slide-y-transition group>
                    <v-card
                      v-for="(tip, tipIndex) in item.tips"
                      :key="`tip-${tipIndex}`"
                      class="mb-5"
                      outlined
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <div class="d-flex justify-space-between">
                              <v-card-title class="pa-0 text-h6">
                                Tip {{ tipIndex + 1 }}/{{ item.tips.length }}
                              </v-card-title>

                              <v-btn icon small class="float-right" @click="onRemoveTip(tipIndex)">
                                <v-icon size="20">
                                  {{ icons.mdiDeleteOutline }}
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-col>

                          <v-col cols="12" sm="6">
                            <vee-text-field v-model="tip.title" label="Title" />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <vee-text-field v-model="tip.subtitle" label="Subtitle" />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <upload-file
                              accept="image/*"
                              rules="imageFile"
                              label="Image url"
                              @uploadSuccess="tip.imageUrl = $event.url"
                            />
                            <vee-text-field
                              :value="tip.imageUrl"
                              :vid="`tip-${tipIndex}`"
                              rules="url"
                              clearable
                              dense
                              filled
                              @input="tip.imageUrl = $event ? $event : ''"
                            />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <vee-select
                              v-model="tip.type"
                              :vid="`tip-${tipIndex}`"
                              :rules="'required|oneOf:' + Object.values(TipType).join(',')"
                              :items="[''].concat(Object.values(TipType))"
                              label="Type*"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-slide-y-transition>

                  <v-btn color="primary" class="mb-4" outlined @click="onAddTip">
                    Add tip
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <h3>Detailed instructions</h3>
                </v-col>
                <v-col cols="12">
                  <vee-text-field v-model="item.details.instructions.title" label="Title" />
                </v-col>

                <v-col cols="12">
                  <v-slide-y-transition group>
                    <v-card
                      v-for="(image, imageIndex) in item.details.instructions.images"
                      :key="`image-${imageIndex}`"
                      class="mb-5"
                      outlined
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <div class="d-flex justify-space-between">
                              <v-card-title class="pa-0 text-h6">
                                Image {{ imageIndex + 1 }}/{{ item.details.instructions.images.length }}
                              </v-card-title>

                              <v-btn icon small class="float-right" @click="onRemoveInstructionImage(imageIndex)">
                                <v-icon size="20">
                                  {{ icons.mdiDeleteOutline }}
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-col>

                          <v-col cols="12" sm="6">
                            <vee-text-field v-model="image.title" label="Title" />
                          </v-col>

                          <v-col cols="12">
                            <upload-file
                              accept="image/*"
                              rules="imageFile"
                              label="Image url"
                              @uploadSuccess="image.imageUrl = $event.url"
                            />
                            <vee-text-field
                              :value="image.imageUrl"
                              :vid="`image-${imageIndex}`"
                              rules="required|url"
                              clearable
                              dense
                              filled
                              @input="image.imageUrl = $event ? $event : ''"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-slide-y-transition>

                  <v-btn color="primary" class="mb-10" outlined @click="onAddInstructionImage">
                    Add instruction image
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="onCancel">
          Cancel
        </v-btn>
        <v-btn text :loading="isLoading" @click="onSave">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDeleteOutline } from '@mdi/js'
import { computed, ref } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { ExerciseCategory, TipType } from '@/enums/enums'
import UploadFile from '@/layouts/components/UploadFile.vue'

export default {
  components: {
    UploadFile,
    ValidationObserver,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
      type: Object,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const formObserver = ref(null)

    const formTitle = computed(() => (
      props.item.id === '' ? 'Create an exercise' : 'Edit the exercise'
    ))

    const onAddTip = () => {
      props.item.tips.push({
        imageUrl: '',
        title: '',
        subtitle: '',
        type: '',
      })
    }

    const onRemoveTip = tipIndex => {
      props.item.tips.splice(tipIndex, 1)
    }

    const onAddInstructionImage = () => {
      props.item.details.instructions.images.push({
        imageUrl: '',
        title: '',
      })
    }

    const onRemoveInstructionImage = imageIndex => {
      props.item.details.instructions.images.splice(imageIndex, 1)
    }

    const onCancel = () => {
      emit('cancel')
    }

    const onSave = async () => {
      const isValid = await formObserver.value.validate()

      if (isValid) {
        emit('save', props.item)
      }
    }

    return {
      formObserver,
      formTitle,

      onAddTip,
      onRemoveTip,

      onAddInstructionImage,
      onRemoveInstructionImage,

      onCancel,
      onSave,

      ExerciseCategory,
      TipType,

      icons: {
        mdiDeleteOutline,
      },
    }
  },
}
</script>
